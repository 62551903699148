/**
 * 収支
 */
import { isBlank } from '@/utilities/typing';
import { displayMonth } from '@/utilities/month';
import Project from './project';
import Progress from './progress';

class Profit {
    static STATUS_CALCULATING = 1;
    static STATUS_FIX = 2;
    static STATUS_UNFIX = 3;

    profit_id;
    project;
    month;
    is_calculating;
    is_fixed;
    sales_price;
    labor_cost;
    subcontract_cost;
    incidental_cost;
    gross_profit;
    adjustment_type;
    adjustment_amount;
    adjustment_note;
    progresses;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.profit_id = properties.profit_id;
        this.project = new Project(properties.project);
        this.month = properties.month;
        this.is_calculating = properties.is_calculating;
        this.is_fixed = properties.is_fixed;
        this.sales_price = properties.sales_price;
        this.labor_cost = properties.labor_cost;
        this.subcontract_cost = properties.subcontract_cost;
        this.incidental_cost = properties.incidental_cost;
        this.gross_profit = properties.gross_profit;
        this.adjustment_type = properties.adjustment_type;
        this.adjustment_amount = properties.adjustment_amount;
        this.adjustment_note = properties.adjustment_note;

        this.progresses = [];
        if ('progresses' in properties) {
            this.progresses = properties.progresses.map((row) => new Progress(row));
        }
    }

    // 原価合計
    get cost_total() {
        return this.labor_cost + this.subcontract_cost + this.incidental_cost;
    }

    // 粗利率
    get gross_profit_rate() {
        if (this.sales_price === 0) {
            return "-";
        } else {
            return (this.gross_profit * 100 / this.sales_price).toFixed(1) + '%';
        }
    }

    // 月表示
    get month_display() {
        return displayMonth(this.month);
    }
}

export default Profit;
