<template>
    <button type="button" @click="clicked">
        <slot />
        <i class="bi bi-caret-up-fill" :class="{'text-primary': isAsc}"></i>
        <i class="bi bi-caret-down-fill" :class="{'text-primary': isDesc}"></i>
    </button>
</template>

<script>
export default {
    name: 'Sorter',
    props: {
        column: {
            type: String,
            default: ''
        },
        orderby: {
            type: String,
            default: ''
        },
        order: {
            type: String,
            default: 'asc'
        },
    },
    mounted() {

    },
    methods: {
        clicked() {
            let orderby = this.orderby;
            let order = this.order;

            if (this.orderby === this.column) {
                order = this.order === 'asc' ? 'desc' : 'asc';
            } else {
                orderby = this.column;
                order = 'asc'
            }

            this.$emit('sort', {orderby, order});
        }
    },
    computed: {
        isAsc() {
            return (this.orderby === this.column && this.order === 'asc');
        },
        isDesc() {
            return (this.orderby === this.column && this.order === 'desc');
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
    border: none;
    background: none;
    position: relative;
    color: inherit;
    padding-right: 1rem;
}
button i:first-child {
    position: absolute;
    right: 0;
    bottom: 40%;
    font-size: 0.7em;
}
button i:last-child {
    position: absolute;
    right: 0;
    top: 40%;
    font-size: 0.7em;
}
</style>
