/**
 * 収支：進捗
 */
import { isBlank } from '@/utilities/typing';
import Phase from './phase';

class Progress {
    progress_id;
    phase;
    progress_rate;
    progress_start;
    progress_end;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.progress_id = properties.progress_id;
        this.phase = new Phase(properties.phase);
        this.progress_rate = properties.progress_rate;
        this.progress_start = properties.progress_start;
        this.progress_end = properties.progress_end;
    }
}

export default Progress;
