<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-line-fill"></i> 案件別収支レポート</h1>
    </header>

    <section class="section">
        <form class="row mb-3" @submit.prevent="fetch()">
            <div class="col-6">
                <div class="input-group">
                    <form-input-month v-model="month_from" required></form-input-month>
                    <span class="input-group-text">〜</span>
                    <form-input-month v-model="month_to" required></form-input-month>
                </div>
            </div>
            <div class="col-4">
                <form-select v-model="department" :options="departments" option_value="department_id" option_label="department_name" empty_option="-- 部門 --" :disabled="departments.length === 0" @change="fetch()"></form-select>
            </div>
            <div class="col-4">
                <form-select v-model="user" :options="users" option_value="user_id" option_label="user_name" empty_option="-- 担当者 --" :disabled="users.length === 0" @change="fetch()"></form-select>
            </div>
            <div class="col-4">
                <form-select v-model="customer" :options="customers" option_value="customer_id" option_label="customer_name" empty_option="-- 顧客 --" :disabled="customers.length === 0" @change="fetch()"></form-select>
            </div>
            <div class="col-4">
                <form-select v-model="billing_customer" :options="customers" option_value="customer_id" option_label="customer_name" empty_option="-- 請求先 --" :disabled="customers.length === 0" @change="fetch()"></form-select>
            </div>
            <div class="col-2">
                <button type="submit" class="btn btn-primary">表示</button>
            </div>
        </form>

        <div class="bg-light p-3 mb-3">
            <div class="row">
                <div class="col-1 text-end text-secondary">売上</div>
                <div class="col-10">
                    <doughnut-chart :dataset="sales_chart_data" :loading="loading"></doughnut-chart>
                </div>
                <div class="col-1 offset-1 text-end text-secondary">粗利</div>
                <div class="col-10">
                    <doughnut-chart :dataset="profit_chart_data" :loading="loading"></doughnut-chart>
                </div>
            </div>
        </div>

        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="project" @sort="fetchSorted">案件</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="sales_price" @sort="fetchSorted">売上</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="cost_total" @sort="fetchSorted">原価</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="gross_profit" @sort="fetchSorted">粗利</sorter></th>
                    <th class="text-center"><sorter :orderby="orderby" :order="order" column="gross_profit_rate" @sort="fetchSorted">粗利率</sorter></th>
                    <th class="text-center"></th>
                </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="profit of profits" :key="profit.month">
                    <td class="">{{ profit.project?.project_name }}</td>
                    <td class="text-end">{{ $helper.number(profit.sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(profit.cost_total) }}</td>
                    <td class="text-end">{{ $helper.number(profit.gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(profit.sales_price, profit.gross_profit)">{{ profit.gross_profit_rate }}</td>
                    <td>
                        <router-link :to="{name: 'ProfitStatsProjectDetail', params: {project: profit.project.project_id}}" class="btn btn-outline-primary btn-sm me-3">案件サマリー</router-link>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in Array(10)" :key="i">
                    <td class=""><text-loader :width="12"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td>
                        <button class="btn btn-outline-primary btn-sm me-3" disabled>案件サマリー</button>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="loading">
                <tr>
                    <td>合計</td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td></td>
                </tr>
            </tfoot>
            <tfoot v-else>
                <tr>
                    <td>合計</td>
                    <td class="text-end">{{ $helper.number(total_sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(total_cost_total) }}</td>
                    <td class="text-end">{{ $helper.number(total_gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(total_sales_price, total_gross_profit)">{{ total_gross_profit_rate }}</td>
                    <td></td>
                </tr>
            </tfoot>
        </table>

    </section>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormSelect from '@/components/forms/FormSelect';
import TextLoader from '@/components/tools/TextLoader';
import Profit from "@/models/entities/profit";
import User from "@/models/entities/user";
import Department from "@/models/entities/department";
import Customer from "@/models/entities/customer";
import DoughnutChart from '@/components/charts/DoughnutChart';
import Sorter from '@/components/tools/Sorter';

export default {
    name: 'ProfitStatsProject',
    components: {
        FormInputMonth,
        FormSelect,
        TextLoader,
        DoughnutChart,
        Sorter,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            profits: [],
            users: [],
            departments: [],
            customers: [],
            orderby: 'project',
            order: 'asc',
        }
    },
    mounted() {
        if (this.$route.query.customer) {
            this.customer = this.$route.query.customer;
        }
        if (this.$route.query.billing_customer) {
            this.billing_customer = this.$route.query.billing_customer;
        }
        this.fetch();

        this.$http.get('/list/user')
        .then((response) => {
            this.users = response.data.map((row) => {return new User(row)});
        })

        this.$http.get('/list/department')
        .then((response) => {
            this.departments = response.data.map((row) => {return new Department(row)});
        })

        this.$http.get('/list/customer')
        .then((response) => {
            this.customers = response.data.map((row) => {return new Customer(row)});
        })
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$http.get('/profit/summary/project', {params: {
                user: this.user,
                department: this.department,
                customer: this.customer,
                billing_customer: this.billing_customer,
                month_from: this.month_from,
                month_to: this.month_to,
                orderby: this.orderby,
                order: this.order,
            }})
            .then((response) => {
                this.profits = [];
                for (let row of response.data) {
                    this.profits.push(new Profit(row));
                }
                this.loading = false;
            });
        },
        fetchSorted(sort)
        {
            this.orderby = sort.orderby;
            this.order = sort.order;

            this.fetch();
        },
        profit_rate_class(sales_price, gross_profit)
        {
            if (sales_price === 0) {
                return null;
            } else {
                const rate = gross_profit * 100 / sales_price;
                if (rate >= this.$store.state.auth.company.profit_rate_safe) {
                    return "text-success";
                } else if (rate < this.$store.state.auth.company.profit_rate_danger) {
                    return "text-danger";
                } else {
                    return "text-warning";
                }
            }
        },
    },
    computed: {
        user: {
            get() {
                return this.$store.state.condition.user;
            },
            set(value) {
                this.$store.commit('condition/setUser', value);
            },
        },
        department: {
            get() {
                return this.$store.state.condition.department;
            },
            set(value) {
                this.$store.commit('condition/setDepartment', value);
            },
        },
        customer: {
            get() {
                return this.$store.state.condition.customer;
            },
            set(value) {
                this.$store.commit('condition/setCustomer', value);
            },
        },
        billing_customer: {
            get() {
                return this.$store.state.condition.billing_customer;
            },
            set(value) {
                this.$store.commit('condition/setBillingCustomer', value);
            },
        },
        month_from: {
            get() {
                return this.$store.state.condition.month_from;
            },
            set(value) {
                this.$store.commit('condition/setMonthFrom', value);
            },
        },
        month_to: {
            get() {
                return this.$store.state.condition.month_to;
            },
            set(value) {
                this.$store.commit('condition/setMonthTo', value);
            },
        },
        total_sales_price() {
            return this.profits.reduce((total, profit) => total + profit.sales_price, 0);
        },
        total_cost_total() {
            return this.profits.reduce((total, profit) => total + profit.cost_total, 0);
        },
        total_gross_profit() {
            return this.profits.reduce((total, profit) => total + profit.gross_profit, 0);
        },
        total_gross_profit_rate() {
            if (this.total_sales_price === 0) {
                return "-";
            } else {
                return (this.total_gross_profit * 100 / this.total_sales_price).toFixed(1) + '%';
            }
        },
        sales_chart_data() {
            // 売上降順ソートして最大上位10件（＋その他）
            if (this.profits.length === 0) {
                return [];
            }

            let positives = this.profits.filter(row => row.sales_price > 0);
            if (positives.length === 0) {
                return [];
            }

            let sorted = Array.from(this.profits).sort((a, b) => {
                return a.sales_price > b.sales_price ? -1 : 1;
            });

            let chart_data = [];
            let others = 0;

            for (let profit of sorted) {
                if (chart_data.length < 10 && profit.sales_price > 0) {
                    chart_data.push({
                        label: profit.project.project_name,
                        value: profit.sales_price,
                    });
                } else {
                    others = others + profit.sales_price;
                }
            }

            if (others > 0) {
                chart_data.push({
                    label: 'その他',
                    value: others,
                });
            }

            return chart_data;
        },
        profit_chart_data() {
            // 粗利降順ソートして最大上位10件（＋その他）
            if (this.profits.length === 0) {
                return [];
            }

            let positives = this.profits.filter(row => row.gross_profit > 0);
            if (positives.length === 0) {
                return [];
            }

            let sorted = Array.from(this.profits).sort((a, b) => {
                return a.gross_profit > b.gross_profit ? -1 : 1;
            });

            let chart_data = [];
            let others = 0;

            for (let profit of sorted) {
                if (chart_data.length < 10 && profit.gross_profit > 0) {
                    chart_data.push({
                        label: profit.project.project_name,
                        value: profit.gross_profit,
                    });
                } else {
                    others = others + profit.gross_profit;
                }
            }

            if (others > 0) {
                chart_data.push({
                    label: 'その他',
                    value: others,
                });
            }

            return chart_data;
        }
    },
}
</script>

<style scoped>
</style>
