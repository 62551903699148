<template>
    <Doughnut
        v-if="loading"
        :chart-data="loadingData"
        :chart-options="chartOptions"
    />
    <Doughnut
        v-else-if="dataset.length > 0"
        :chart-data="chartData"
        :chart-options="chartOptions"
    />
    <div v-else class="d-flex align-items-center justify-content-center" style="aspect-ratio: 1.2;">
        <strong class="text-secondary">NO DATA</strong>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'vue-chartjs';

// 円グラフ上にラベルを表示する場合はプラグインが必要
// https://tech-market.org/vuechartjs-pie-label-on-graph/
ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    name: 'DoughnutChart',
    components: { Doughnut },
    props: {
        dataset: {
            type: Array,
            default: () => [] // {label, value} オブジェクトの配列
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loadingData: {
                labels: ['Loading...'],
                datasets: [
                    {
                        backgroundColor: ['#EEE'],
                        data: [100],
                    }
                ]
            }
        }
    },
    computed: {
        chartData() {
            return {
                labels: this.dataset.map(row => row.label),
                datasets: [
                    {
                        backgroundColor: this.colors,
                        data: this.dataset.map(row => row.value),
                    }
                ]
            }
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false
            }
        },
        colors() {
            const palette = [
                '#de6474',
                '#deca64',
                '#ce64de',
                '#b1de64',
                '#6474de',
                '#de9164',
                '#64dece',
                '#de64b1',
                '#74de64',
                '#9164de',
                '#dece64',
            ];
            let colors = [];
            for (let i = 0; i < this.dataset.length; i++) {
                colors.push(palette[i % 11]);
            }

            return colors;
        }
    }
}
</script>